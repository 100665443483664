import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DebtValue from './DebtValue'
import AssetValue from './AssetValue'
import LoanTime from './LoanTime'
import SACvsPrice from './SACvsPrice'
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const LoanData = (props) => {
  const [debtValue, setDebtValue] = React.useState(props.debtValue)
  const [assetValue, setAssetValue] = React.useState(props.assetValue);
  const [loanTime, setLoanTime] = React.useState(props.loanTime);
  const [table, setTable] = React.useState(props.table);
  console.log('loanbTime: ', loanTime)
  const [sellOrPay, setSellOrPay] = React.useState(props.sellOrPay);

  const handleCallbackDebt = (debtValue) => {
    setDebtValue(debtValue)
    props.debtCallback(debtValue)
  }

  const handleCallbackLoanTime = (loanTime) => {
    setLoanTime(loanTime)
    props.timeCallback(loanTime)
  }

  const handleCallbackAssetValue = (assetValue) => {
    setAssetValue(assetValue)
    props.assetCallback(assetValue)
  }

  const handleCallbackTable = (table) => {
    setTable(table)
    props.tableCallback(table)
  }

  return (
    <div>
      <Box sx={{ width: '100%' }} style={{marginBottom: '5%'}}>
        <DebtValue debt={debtValue} last={true}
            type={props.type}
            callback={handleCallbackDebt}/>
        <AssetValue assetValue={assetValue} last={true}
            callback={handleCallbackAssetValue}/>
        <LoanTime sellOrPay={sellOrPay} last={true}
            loanTime={loanTime}
            callback={handleCallbackLoanTime}/>
        {/* {sellOrPay == "pay" && */}
        <SACvsPrice last={true} callback={handleCallbackTable} table={table}/>
        {/* } */}
      </Box>
    </div>
  )
}

export default LoanData;