import React from 'react';

const Mandatory = () => {
    return (
        <div style={{
            'float': "left",
            'color': 'red',
            'marginBottom': '1%'
        }}>
            *Obrigatório
        </div>
    )
};

export default Mandatory;