import React from "react";
import { AppBar, Toolbar, Stack, Button, IconButton, Drawer, ListSubheader, List, ListItem, ListItemButton, ListItemText, Collapse } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import mainLogo from'/home/victor/solvefin/solve-web/src/assets/logo.png';


const Navbar = (props) => {
  const { innerWidth, innerHeight } = window;
  console.log('widht: ', innerWidth)
  const isMobile = innerWidth <= 1000;
  console.log('isMobile: ', isMobile)
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openFale, setOpenFale] = React.useState(false);


  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickFaleComAGente = () => {
    console.log('fale com a gente')
    setOpenFale(!openFale);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };



  return (
    <div>
    {isMobile && 
    <Drawer
      // container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
    >
      <List
        sx={{ width: '100%', maxWidth: 190, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Páginas
          </ListSubheader>
        }
      >
      <ListItemButton onClick={handleClick}>
        <ListItemText primary="Nossas soluções" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
              <a style={{color: 'black'}} href="https://solvefin.com.br/divida-de-condominio/">
                Dívida condominial
              </a>
            </ListItemText>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
              <a style={{color: 'black'}} href="https://solvefin.com.br/emprestimo-com-garantia-de-imovel/">
                Empréstimo com garantia de imóvel
              </a>
            </ListItemText>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
              <a style={{color: 'black'}} href="https://solvefin.com.br/divida-de-financiamento-auto/">
                Financiamento com garantia de automóvel (em breve)
              </a>
            </ListItemText>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
              <a style={{color: 'black'}} href="https://solvefin.com.br/creditos-judiciais/">
                Créditos Judiciais (em breve)
              </a>
            </ListItemText>
          </ListItemButton>
        </List>
      </Collapse>
        <ListItemButton>
          <ListItemText>
            <a style={{color: 'black'}} href="https://solvefin.com.br/advogados/">
              Advogados
            </a>
          </ListItemText>
        </ListItemButton>
        <ListItemButton>
            <ListItemText>
              <a style={{color: 'black'}} href="https://solvefin.com.br/condominio/">
                Condomínio
              </a>
            </ListItemText>
          </ListItemButton>
      <ListItemButton onClick={handleClickFaleComAGente}>
        <ListItemText primary="Fale com a gente" />
        {openFale ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openFale} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
              <a style={{color: 'black'}} href="https://solvefin.com.br/fale-com-a-gente-divida-condominial/">
                Dívida condominial
              </a>
            </ListItemText>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
              <a style={{color: 'black'}} href="https://solvefin.com.br/fale-com-a-gente-home-equity/">
                Empréstimo com garantia de imóvel
              </a>
            </ListItemText>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
              <a style={{color: 'black'}} href="https://solvefin.com.br/fale-com-a-gente-advogado/">
                Sou advogado
              </a>
            </ListItemText>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
              <a style={{color: 'black'}} href="https://solvefin.com.br/fale-com-a-gente-sindico/">
                Sou síndico
              </a>
            </ListItemText>
          </ListItemButton>
        </List>
      </Collapse>
      </List>
    </Drawer>}
    <AppBar position='static' style={{
          backgroundColor: "#D607BA"
      }}>
      <Toolbar> 
        {/* <img src="logo.png"/> */}
        <img src={mainLogo} style={{marginRight: 'auto',marginTop: '0.5%', marginBottom: '0.5%'}}></img>
        <Stack direction='row' spacing={2}>
        {!isMobile &&
        <div>
          <Button color='inherit' href="https://solvefin.com.br/fale-com-a-gente/"  style={{  margin: 'auto'}}><strong>FALE COM A GENTE</strong></Button> 
          <Button color='inherit' href="https://solvefin.com.br/divida-de-condominio/" style={{  margin: 'auto'}}><strong>EMPRÉSTIMO COM GARANTIA</strong></Button>
          <Button color='inherit' href="https://solvefin.com.br/emprestimo-com-garantia-de-imovel/" style={{  margin: 'auto'}}><strong>DÍVIDA DE CONDOMÍNIO</strong></Button> 
          <Button color='inherit' href="https://solvefin.com.br/advogados/" style={{  margin: 'auto'}}><strong>ADVOGADOS</strong></Button> 
          <Button color='inherit' href="https://solvefin.com.br/condominio/" style={{  margin: 'auto'}}><strong>CONDOMÍNIO</strong></Button></div>
        }
        {isMobile && 
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            // sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>}
        </Stack>
      </Toolbar>
      {/* <NavLink to='/' 
        style={({ isActive }) => {
          return { color: isActive ? 'red' : 'grey' };
        }}
      >Home</NavLink>
      <NavLink to='/products'>Products</NavLink> */}
    </AppBar>
    </div>
  );
};
export default Navbar;