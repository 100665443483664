import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import {makeStyles} from '@mui/styles'
import { Step, StepLabel, Stepper, Button, Snackbar, Alert } from '@mui/material';
import Owner from '../components/due-diligence/Owner'
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { useSearchParams } from "react-router-dom";
import Asset from "../components/due-diligence/Asset";
import api from '../services/api.js'
import { send } from '../queries/posts.js'
import { assetInfoNotValid, userInfoNotValid } from '../utils/masks';

const Home = () => {
    let navigate = useNavigate();

    const [message, setMessage] = useState([])
    const [ownersHeirs, setOwnersHeirs] = useState([{
                                                person: "fisica", email: null, firstname: null, lastname: null, phone: null, birthdate: null, cpf: null, address: null, link_do_drive: '',
                                                rg: null, maritalState: null, dead: false, identity: null, proofOfResidence: null, proofOfDeath: null, proofOfMaritalState: null,
                                                proofOfDeathName: ' ', proofOfMaritalStateName: ' ', proofOfResidenceName: ' ' , identityName: ' ', cpnj: null, razaoSocial: null}]);
    
    const [state, setState] = useState(0);
    const [razaoSocial, setRazaoSocial] = useState(null)
    const [person, setPerson] = useState("fisica")
    const [dealname, setDealname] = useState('')
    const [dealLink, setDealLink] = useState(null)
    const [owner, setOwner] = useState(ownersHeirs[0])
    const [email, setEmail] = useState(null)
    const [firstname, setFirstname] = useState(null)
    const [lastname, setLastname] = useState(null)
    const [phone, setPhone] = useState(null)
    const [birthdate, setBirthdate] = useState(null)
    const [cpf, setCPF] = useState(null)
    const [rg, setRG] = useState(null)
    const [maritalState, setMaritalState] = useState(null)
    const [dead, setDead] = useState(false)
    const [address, setAddress] = useState(null)
    const [identity, setIdentity] = useState(null)
    const [identityName, setIdentityName] = useState(' ')
    const [proofOfResidence, setProofOfResidence] = useState(null)
    const [proofOfResidenceName, setProofOfResidenceName] = useState(' ')
    const [proofOfDeath, setProofOfDeath] = useState(null)
    const [proofOfDeathName, setProofOfDeathName] = useState(' ')
    const [proofOfMaritalState, setProofOfMaritalState] = useState(null)
    const [proofOfMaritalStateName, setProofOfMaritalStateName] = useState(' ')
    const [ownerLink, setOwnerLink] = useState(' ')
    const [searchParams, setSearchParams] = useSearchParams();
    const [dealId, setDealId] = useState();
    const [open, setOpen] = useState(false);
    const [done, setDone] = useState(false);
    const [isPaid, setIsPaid] = useState(false);

    const [sell, setSell] = useState(false);
    const [debt, setDebt] = useState(0);
    const [condDebt, setCondDebt] = useState(0);
    const [proofOfDebt, setProofOfDebt] = useState(null);
    const [proofOfDebtName, setProofOfDebtName] = useState(null);
    const [proofOfCondDebt, setProofOfCondDebt] = useState(null);
    const [proofOfCondDebtName, setProofOfCondDebtName] = useState(null);


    const handleCallbackSell = (sell) => {
        setSell(sell)
    }

    const handleCallbackDebt = (debt) => {
        setDebt(debt)
    }

    const handleCallbackCondDebt = (debt) => {
        setCondDebt(debt)
    }

    const handleChangeProofOfDebt = (proofOfDebt) => {
        // console.log('proofOfDebtName handleChangeProofOfDebt: ', proofOfDebt, proofOfDebtName)
        setProofOfDebt(proofOfDebt)
        setProofOfDebtName(proofOfDebt.name)
    }

    const handleChangeProofOfCondDebt = (proofOfCondDebt) => {
        setProofOfCondDebt(proofOfCondDebt)
        setProofOfCondDebtName(proofOfCondDebt.name)
    } 

    const handleCallbackIsPaid = (isPaid) => {
        setIsPaid(isPaid)
        if(isPaid){
            setDebt("R$ 0,00")
        }
    }

    const [mobileOpen, setMobileOpen] = useState(true);

    useEffect(async () => {
        // Runs only on the first render
        let params = (new URL(document.location)).searchParams;
        let dealId = params.get('dealId');
        // console.log('dealId: ', dealId)
        setDealId(dealId)
        const res = await api.get('due-diligence?dealId=' + dealId)
        const data = res.data
        // data[]
        console.log('data USEeFFECT: ', data)

        setDealLink(data["dealLink"])
        setDealname(data["dealname"])
        setDebt(data["debt"] == null ? "R$ 0,00":filterDebt(data["debt"]))
        setCondDebt(data["condDebt"] == null ? "R$ 0,00":filterDebt(data["condDebt"]))
        setIsPaid(data["isPaid"])
        setSell(data["sell"] == null ? true:data["sell"])
        setProofOfDebtName(data["proofOfDebtName"])
        setProofOfCondDebtName(data["proofOfCondDebtName"])

        const list = []
        setFull(data["owners"][0])
        data["owners"].forEach((owner, i) => {
            // console.log('person: ', (person == null ? "fisica":person))
            list.push({...owner, person:(person == null ? "fisica":person)})
        })
        // console.log('list ====>> ', list)
        setOwnersHeirs(list);
    }, []);

    useEffect(() => {
        // console.log('IsPaid: ', isPaid)
    }, [])

    useEffect(() => {
        // console.log('person changed: ', person)
    }, [person])

    
    const handleClose = () => {
        setOpen(false)
    }

    const pushOwner = (ownersHeirs) => {
        // console.log('state-oush owner: ', state, identityName, proofOfDeathName, proofOfMaritalStateName, proofOfResidenceName, person )
        console.log('ownersHeirs: ', ownersHeirs)
        console.log('state: ', state)
        ownersHeirs[state].email = email
        ownersHeirs[state].person = person
        ownersHeirs[state].firstname = firstname
        ownersHeirs[state].lastname = lastname
        ownersHeirs[state].phone = phone
        ownersHeirs[state].birthdate = birthdate
        ownersHeirs[state].cpf = cpf
        ownersHeirs[state].rg = rg
        ownersHeirs[state].maritalState = maritalState
        ownersHeirs[state].dead = dead
        ownersHeirs[state].address = address
        ownersHeirs[state].identity = identity
        ownersHeirs[state].proofOfDeath = proofOfDeath
        ownersHeirs[state].proofOfMaritalState = proofOfMaritalState
        ownersHeirs[state].proofOfResidence = proofOfResidence
        ownersHeirs[state].identityName = identityName
        ownersHeirs[state].proofOfDeathName = proofOfDeathName
        ownersHeirs[state].proofOfMaritalStateName = proofOfMaritalStateName
        ownersHeirs[state].proofOfResidenceName = proofOfResidenceName
        ownersHeirs[state].link_do_drive = ownerLink
    }

    const setFull = (owner) => {
        setOwner(owner)
        setPerson(owner.person)
        setFirstname(owner.firstname)
        setLastname(owner.lastname)
        setAddress(owner.address)
        setBirthdate(owner.birthdate)
        setDead(owner.dead)
        setCPF(owner.cpf)
        setRG(owner.rg)
        setMaritalState(owner.maritalState)
        setPhone(owner.phone)
        setEmail(owner.email)
        setIdentity(owner.identity)
        setProofOfDeath(owner.proofOfDeath)
        setProofOfMaritalState(owner.proofOfMaritalState)
        setProofOfResidence(owner.proofOfResidence)
        setIdentityName(owner.identityName)
        setProofOfDeathName(owner.proofOfDeathName)
        setProofOfMaritalStateName(owner.proofOfMaritalStateName)
        setProofOfResidenceName(owner.proofOfResidenceName)
        setOwnerLink(owner.link_do_drive)
    }

    const handleCallbackEmail = (email) => { 
        setEmail(email)
    }

    function filterDebt(value)
    {
        console.log('value first: ' + value)
        const options = { minimumFractionDigits: 2 }
        const result = 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(
          parseFloat(value)
          )
        return result
    }

    const handleCallbackFirstname = (name) => {
        setFirstname(name)
    }

    const handleCallbackAddress = (addr) => {
        setAddress(addr)
    }

    const handleChangeProofOfIdentity = (identity) => {
        setIdentity(identity)
        setIdentityName(identity.name)
    }

    const handleChangeProofOfResidence = (proofOfResidence) => {
        // console.log('proofOfResidence: ', proofOfResidence)
        setProofOfResidence(proofOfResidence)
        setProofOfResidenceName(proofOfResidence.name)
    }

    const handleChangeProofOfDeath = (proofOfDeath) => {
        // console.log('proofOfDeath: ', proofOfDeath)
        setProofOfDeath(proofOfDeath)
        setProofOfDeathName(proofOfDeath.name)
    }

    const handleChangePerson = (person) => {
        setPerson(person)
    }


    const handleChangeProofOfMaritalState = (proofOfMaritalState) => {
        // console.log('proofOfMaritalState: ', proofOfMaritalState)
        setProofOfMaritalState(proofOfMaritalState)
        setProofOfMaritalStateName(proofOfMaritalState.name)
    }

    const handleCallbackLastname = (name) => {
        setLastname(name)
    }

    const handleCallbackPhone = (phone) => {
        setPhone(phone)
    }

    const handleCallbackBirthdate = (birthdate) => {
        setBirthdate(birthdate)
    }

    const handleCallbackCpf = (cpf) => {
        setCPF(cpf)
    }

    const handleCallbackRG = (rg) => {
        setRG(rg)
    }

    const handleCallbackMaritalState = (maritalState) => {
        setMaritalState(maritalState)
    }

    const handleCallbackDead = (dead) => {
        setDead(dead)
    }

    const phoneIsInvalid = (phone) => {
        // console.log('phone ===> ', phone)
        return phone.length < 10
    }


    const { innerWidth } = window;
    const isMobile = innerWidth <= 1000;

    async function sendData()
    {
        var assetErros = assetInfoNotValid(isPaid, proofOfDebtName, proofOfCondDebtName)
        var userErros = userInfoNotValid(ownersHeirs)
        console.log('assetErros: ', assetErros)
        console.log('userErros: ', userErros, userErros[0].length, userErros[0].length > 0)

        var userError = userErros.some((userError) => userError.length > 0)
        

        if(assetErros.length > 0 || userError)
        {
            var msg = ["Documentos faltando: ", " - Documentos do imóvel:"]
            assetErros.forEach((error, i) => {
                msg.push(" " + String(i+1) + ". " + error)
            })
            userErros.forEach((notValid, i) => {
                if(notValid.length > 0)
                {
                    msg.push(" - Proprietário " + String(i+1) + ":")
                    notValid.forEach((doc, n) => {
                        msg.push(" " + String(n+1) + ". " + doc)
                    })
                }
            })
            console.log('msg: ', msg)
            setMessage(msg)
            setDone(false)
            setOpen(true)
        }
        else
        {
            var res = await send(ownersHeirs, debt, condDebt, sell, isPaid, proofOfDebt, proofOfCondDebt, dealId, dealname, dealLink, proofOfDebtName, proofOfCondDebtName)
            // console.log('res aftder post:', res)
            var listOfIds = res.data['listOfIds']
            // console.log('listOfIds:', listOfIds)

            setOwnersHeirs(ownersHeirs.map((obj, i) => {
                // console.log('obj:', obj)
                return {
                    ...obj,
                    id: listOfIds[i]
                }
            }))

            setDone(res.data['done'])
            if(res.data['done'] == true)
            {
                setMessage(['Enviado com sucesso!'])
            }
            else
            {
                setMessage(['Erro ao realizar envio'])
            }

            setOpen(true)
        }
    }

    const addOwner = () => 
    {
        console.log('(ownersHeirs).length-1: ', (ownersHeirs).length-1, state)
        if(state != (ownersHeirs).length - 1)
        {
            if(state == (ownersHeirs).length)
            {
                console.log('---------------- adicionar usuario --------------')
                var owner = {
                    person: 'fisica',
                    email: null,
                    firstname: '',
                    lastname: '',
                    phone: '',
                    birthdate: '',
                    cpf: '',
                    rg: '',
                    address: '',
                    maritalState: null,
                    dead: "Não",
                    identity: null,
                    proofOfDeath: null,
                    proofOfMaritalState: null,
                    proofOfResidence: null,
                    identityName: ' ',
                    proofOfDeathName: ' ',
                    proofOfMaritalStateName: ' ',
                    proofOfResidenceName: ' ',
                    link_do_drive: ' '
                }
                setOwnersHeirs(ownersHeirs => [...ownersHeirs, owner]);
                
            }
            else
            {
                var owner = ownersHeirs[state+1]
            }
            setFull(owner)
    
            // console.log('ownersHeirs: ', ownersHeirs)
        }
        pushOwner(ownersHeirs)
        setState(state+1)
        console.log('---------------- ir para imóvel --------------')

    }

    const deleteOwner = async () => {
        
        if(ownersHeirs.length > 1)
        {
            // console.log('state for slicing----------: \n', state)
            setOwnersHeirs(
                ownersHeirs.filter(owner =>
                    owner.firstname !== firstname
                    )
                );
            let newState = 0      
            if(state-1 >= 0)
            {
                newState = state-1
            }
            else
            {
                newState = 0
            }
            setState(newState)
            var owner = ownersHeirs[newState]
            
            setFull(owner) 
            if(ownersHeirs[state]['id'] != null)
            {
                let contactId = ownersHeirs[state]['id']
                // console.log('contactId -----> ', contactId)
                const res = await api.get('delete?contactId=' + contactId) 
            }
        }
    }

    const onPrevious = () => {
        // console.log('previous: ', state, firstname)
        
        if(state == ownersHeirs.length)
        {
            setState(state-1)
            var owner = ownersHeirs[state-1]
            // console.log('owner: ', owner)
            setFull(owner)
        }
        else if(state > 0)
        {
            pushOwner(ownersHeirs)
            setState(state-1)
            var owner = ownersHeirs[state-1]
            // console.log('owner previous: ', owner)
            setFull(owner)
        }
    }
    // const steps = ['Tipo','Valor','Garantia','Forma de pagamento','Prazo','Simulação'];
    const useStyles = makeStyles(() => ({
        root: {
            "& .MuiStepIcon-active": { color: "#D607BA" }
        }
    }));

    const c = useStyles();

    return (
    
        <div style={{
            'width': '100%',
            'padding': '2%'
        }}>
            <div align="center">
                <span><strong>Proprietários registrados na matrícula, e se for o caso, seus cônjuges e herdeiros</strong></span>
            </div>
            <Stepper activeStep={state} alternativeLabel style={{'marginBottom':'4%','marginTop':'2%'}}>
                {ownersHeirs.map((owner, index) => {
                    return (
                        <Step size="large" key={owner.firstname}>
                            <StepLabel size="large">{owner.firstname}</StepLabel>
                        </Step>
                    );
                })}
                <Step size="large" key={"Imóvel"}>
                    <StepLabel size="large">{"Imóvel"}</StepLabel>
                </Step>
            {isMobile ? null:
                <Button size="large" onClick={deleteOwner} startIcon={<DeleteIcon />} style={{marginRight: '5%', float: 'right', color:"red"}}>
                    Deletar
                </Button>}
            </Stepper>
            <div align="center"  style={{'marginBottom': '2%'}}>
                <span style={{'fontSize': '2em'}}>
                    <strong>{dealname}</strong>
                    <br/>
                </span>
            </div>
            <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }} open={open} autoHideDuration={6000} onClose={handleClose}>
                {done == true ? 
                <Alert severity="success" sx={{ width: '100%' }}>  
                    {message.map((msg) => {
                        return (
                            <div>
                                <strong>{msg}</strong>
                                <br/>
                            </div>
                        )
                    })}
                </Alert>
                :
                <Alert severity="error" sx={{ width: '100%' }}>
                                  
                    {message.map((msg) => {
                        return (
                            <div>
                                {msg}
                                <br/>
                            </div>
                        )
                    })}
                </Alert>
                }
            </Snackbar>
            <div align={"center"} class="row" style={{
                'width': `${isMobile ? "100%":"90%"}`,
                'marginBottom': "10%",
            }}>
                <div style={{
                    "width": "100%",
                    'float':'left',
                    "marginLeft": `${isMobile ? "0%":"5%"}`,
                    "paddingTop": `${isMobile ? "10%" : "0%"}`
                }}>
                    {state < ownersHeirs.length ? 
                    <Owner size="large" isMobile={isMobile} callbackEmail={handleCallbackEmail} callbackFirstname={handleCallbackFirstname}
                        callbackLastname={handleCallbackLastname} callbackBirthdate={handleCallbackBirthdate} callbackCpf={handleCallbackCpf}
                        callbackRg={handleCallbackRG} callbackDead={handleCallbackDead} callbackMaritalState={handleCallbackMaritalState}
                        callbackPhone={handleCallbackPhone} callbackAddress={handleCallbackAddress} handleChangeProofOfIdentity={handleChangeProofOfIdentity}
                        handleChangeProofOfDeath={handleChangeProofOfDeath} callbackPerson={handleChangePerson} handleChangeProofOfMaritalState={handleChangeProofOfMaritalState}
                        handleChangeProofOfResidence={handleChangeProofOfResidence} owner={owner} handleChangePerson={handleChangePerson}>
                    </Owner>
                    :
                    <Asset size="large" isMobile={isMobile} callbackIsPaid={handleCallbackIsPaid} callbackDebt={handleCallbackDebt} proofOfCondDebtName={proofOfCondDebtName}
                        callbackSell={handleCallbackSell} callbackChangeProofOfDebt={handleChangeProofOfDebt} sell={sell} debt={debt} proofOfDebtName={proofOfDebtName}
                        callbackChangeProofOfCondDebt={handleChangeProofOfCondDebt} callbackCondDebt={handleCallbackCondDebt} condDebt={condDebt} isPaid={isPaid} proofOfDebt={proofOfDebt} proofOfCondDebt={proofOfCondDebt}
                        >
                    </Asset>
                    }
                </div>
                <div style={{
                    "marginLeft": `${isMobile ? "0%":"5%"}`
                }}>
                    {state != 0 ?
                    <Button
                        variant="outlined"
                        onClick={onPrevious}
                        fullWidth = {isMobile}
                        style={{
                            float:`${isMobile ? "":"left"}`,
                            color: "#D607BA",
                            fontSize: '100%',
                            borderColor: "#D607BA",
                            marginTop: `${isMobile ? "7%":"0%"}`
                        }} size="large">
                        <strong>ANTERIOR</strong>
                    </Button>:null
                    }
                    <Button
                        variant="outlined"
                        onClick={addOwner}
                        fullWidth = {isMobile}
                        style={{
                            color: "#00B6E8",
                            fontSize: '100%',
                            borderColor: "#00B6E8",
                            float:`${isMobile ? "":"center"}`,
                            marginTop: `${isMobile ? "7%":"0%"}`
                        }} size="large">
                        <strong>{state == (ownersHeirs.length) ? "ADICIONAR PROPRIETÁRIO":"PRÓXIMO"}</strong>
                    </Button>
                    {state == (ownersHeirs.length) ? 
                        <Button
                            variant="outlined"
                            onClick={sendData}
                            fullWidth = {isMobile}
                            endIcon={<SendIcon />}
                            style={{
                                float:`${isMobile ? "":"right"}`,
                                color: "#11212D",
                                fontSize: '100%',
                                borderColor: "#11212D",
                                marginTop: `${isMobile ? "7%":"0%"}`
                            }} size="large">
                            <strong>ENVIAR</strong>
                        </Button>:null
                    }
                    {isMobile ?
                        <div>
                            <Button
                                onClick={deleteOwner}
                                fullWidth
                                startIcon={<DeleteIcon />}
                                style={{
                                    color: "red",
                                    marginTop: "7%"
                                }} size="large">
                                <strong>Deletar</strong>
                            </Button>
                        </div>
                        :null
                    }
                </div>
            </div>
        </div>
    );
};
export default Home;