
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Simulator from './pages/Simulator';
import Serasa from './pages/Serasa';
import About from './pages/About';
import Products from './pages/Products';
import Error from './pages/Error';
import SharedLayout from './pages/SharedLayout';
import Contact from './pages/Contact'
import ThankYou from './pages/ThankYou'
import DueDiligence from './pages/DueDiligence'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<SharedLayout />}>
          {<Route path='/serasa' element={<Serasa/>}/>}
          {/* <Route path='thankyou' element={<ThankYou/>}/> */}
          {/* <Route path='contact' element={<Contact />}/> */}
          {<Route path='' element={<DueDiligence />}/>}
          {/* <Route path='about' element={<About />} />
          <Route path='products' element={<Products />} />
          <Route path='*' element={<Error />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
