export function validateCPF(cpf) {
    if (!cpf) return false;
    var cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11) return  false;
    // console.log('result1: ', result)
    if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      return  false;
    }
    // console.log('result2: ', result)
    let sum = 0;
    let rest;
    for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(9, 10))) return  false;
    sum = 0;
    // console.log('result3: ', result)
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(10, 11))) return  false;
    return  true;
}

export function validateCNPJ(cnpj) {
    if (!cnpj) return false;
    var cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj.length !== 14) return false;
    // console.log('result1: ', result)
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) {
      return false;
    }
    // console.log('result2: ', result)
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    // console.log('result3: ', result)
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;
    return true;
}

export function emailIsValid(email)
{
    var isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    return isValid
}

export function assetInfoNotValid(isPaid, proofOfDebtName, proofOfCondDebtName)
{
    var erros = []
    if (!isPaid && !proofOfDebtName) erros.push('Extrato do saldo devedor com o banco')
    if(!proofOfCondDebtName) erros.push('Comprovante atualizado de débito condominial')
    return erros
}

export function userInfoNotValid(owners)
{
    var notValid = []
    console.log('owners ---> ', owners)
    owners.forEach(owner => {
        var erros = []
        // console.log('--->--->', owner.identityName, owner.identityName == '', owner.proofOfDeathName ,owner.proofOfDeathName == '', owner.proofOfResidenceName, owner.proofOfResidenceName == '', owner.proofOfMaritalStateName, owner.proofOfMaritalStateName == '')
        if (!emailIsValid(owner.email)) erros.push('Email inválido')
        if (owner.person == 'fisica' && !owner.maritalState) erros.push('Estado civil')
        if (owner.person == 'fisica' && !owner.birthdate) erros.push('Data de nascimento')
        if (owner.person == 'fisica' && !owner.address) erros.push('Endereço')
        if (owner.person == 'fisica' && owner.dead == ' ') erros.push('Falecido')
        if (owner.person == 'fisica' && !owner.rg) erros.push('RG')
        if (owner.person == 'fisica' && !owner.firstname) erros.push('Nome')
        if (owner.person == 'fisica' && !owner.lastname) erros.push('Sobrenome')
        if (owner.person == 'fisica' && !validateCPF(owner.cpf)) erros.push('CPF inválido')
        if (owner.person == 'juridica' && !validateCNPJ(owner.cpf)) erros.push('CNPJ inválido')
        if (!owner.phone) erros.push('Telefone inválido')
        if (owner.person == 'fisica' && owner.identityName == ' ') erros.push('Identidade')
        if (owner.person == 'fisica' && (owner.dead == 'Sim' && owner.proofOfDeathName == ' ')) erros.push('Certidão de óbito')
        if (owner.person == 'fisica' &&  (owner.proofOfResidenceName == ' ')) erros.push('Comprovante de residência')
        if (owner.person == 'fisica' && ((owner.maritalState == 'Casado(a)' || owner.maritalState == 'Divorciado(a)') && owner.proofOfMaritalStateName == ' ')) erros.push('Comprovante de estado civil')
        notValid.push(erros)
    })
    return notValid
}