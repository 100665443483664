import { TextField, RadioGroup, Radio, DialogActions, FormLabel, FormControlLabel, Button, MenuItem, Dialog, DialogContent, DialogContentText } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputMask } from 'react-text-mask';
import { validateCPF, validateCNPJ, emailIsValid } from '../../utils/masks.js'
import Mandatory from '../messages/Mandatory'

const useStyles = makeStyles((theme) => ({
    input: {
      display: 'none',
    },
  }));

const maritalStates = ["Casado(a)","Solteiro(a)",
                        "Divorciado(a)","Viúvo(a)"]

const Owner = (props) => {
    const isMobile = props.isMobile
    const [person, setPerson] = React.useState(props.owner.person)
    
    const [email, setEmail] = React.useState(props.owner.email)
    const [firstname, setFirstname] = React.useState(props.owner.firstname)
    const [lastname, setLastname] = React.useState(props.owner.lastname)
    const [phone, setPhone] = React.useState(props.owner.phone)
    const [birthdate, setBirthdate] = React.useState(props.owner.birthdate)
    const [cpf, setCPF] = React.useState(props.owner.CPF)
    const [cpfIsValid, setCPFIsValid] = React.useState(true)
    const [rg, setRG] = React.useState(props.owner.RG)
    const [address, setAddress] = React.useState(props.owner.address)
    const classes = useStyles();
    const [proofOfResidence, setProofOfResidence] = React.useState(null);
    const [proofOfMaritalState, setProofOfMaritalState] = React.useState(null);
    const [proofOfDeath, setProofOfDeath] = React.useState(null);
    const [identity, setIdentity] = React.useState(null);
    const [proofOfResidenceName, setProofOfResidenceName] = React.useState(props.owner.proofOfResidenceName);
    const [proofOfMaritalStateName, setProofOfMaritalStateName] = React.useState(props.owner.proofOfMaritalStateName);
    const [proofOfDeathName, setProofOfDeathName] = React.useState(props.owner.proofOfDeathName);
    const [identityName, setIdentityName] = React.useState(props.owner.identityName);
    const [maritalState, setMaritalState] = React.useState(props.owner.maritalState);
    const [openMaritalAlert, setOpenMaritalAlert] = React.useState(false);
    const [openDead, setOpenDead] = React.useState(false);
    const [dead, setDead] = React.useState(props.owner.dead);

    const identityRef = useRef(null);
    const residenceRef = useRef(null);
    const maritalStateRef = useRef(null);
    const deathRef = useRef(null);

    useEffect(() => {
        console.log('props.owner: ', props.owner);
        setPerson(props.owner.person == undefined ? "fisica":props.owner.person)
        setFirstname(props.owner.firstname)
        setLastname(props.owner.lastname)
        setAddress(props.owner.address)
        setBirthdate(props.owner.birthdate)
        setDead(props.owner.dead)
        setCPF(props.owner.cpf)
        setRG(props.owner.rg)
        setMaritalState(props.owner.maritalState)
        setPhone(props.owner.phone)
        setEmail(props.owner.email)
        // identityRef.current.value = null;
        // residenceRef.current.value = null;
        // maritalStateRef.current.value = null;
        // deathRef.current.value = null;
        setIdentity(props.owner.identity)
        setProofOfResidence(props.owner.proofOfResidence)
        setProofOfDeath(props.owner.proofOfDeath)
        setProofOfMaritalState(props.owner.proofOfMaritalState)
        setIdentityName(props.owner.identityName)
        setProofOfResidenceName(props.owner.proofOfResidenceName)
        setProofOfDeathName(props.owner.proofOfDeathName)
        setProofOfMaritalStateName(props.owner.proofOfMaritalStateName)
     }, [props.owner])


    const [error, setError] = React.useState(null);

    const handleCloseMaritalAlert = (event) => {
        setOpenMaritalAlert(false)
    }

    const handleCloseDead = (event) => {
        setOpenDead(false)
    }

    const changePerson = (event) => {
        setPerson(event.target.value)
        if(event.target.value == 'fisica')
        {
            setLastname('')
            setRG('')
            setAddress('')
            setBirthdate('')
            setProofOfResidenceName('')
            setIdentityName('')
        }
        props.handleChangePerson(event.target.value)
    }

    const changeDead = (event) => {
        setOpenDead(event.target.value == 'Sim')    
        setDead(event.target.value)
        props.callbackDead(event.target.value)
    }

    const changeMaritalState = (event) => {
        setMaritalState(event.target.value)
        // console.log('\n\n\nevent.target.value marital state: ', event.target.value)
        setOpenMaritalAlert(event.target.value == 'Casado(a)' || event.target.value == 'Divorciado(a)')
        props.callbackMaritalState(event.target.value)
    }

    const handleClickIdentity = () => {
        identityRef.current.click();
    };

    const changeProofOfIdentity = (event) => {
        // console.log('changeProofOfIdentity')
        setIdentity(event.target.files[0])
        setIdentityName(event.target.files[0].name);
        props.handleChangeProofOfIdentity(event.target.files[0])
    };

    const handleClickResidence = () => {
        residenceRef.current.click();
    };

    const changeProofOfResidence = (event) => {
        // console.log('changeProofOfResidence')
        setProofOfResidence(event.target.files[0]);
        setProofOfResidenceName(event.target.files[0].name);
        props.handleChangeProofOfResidence(event.target.files[0])
    }

    const handleClickDeath = () => {
        deathRef.current.click();
    };

    const changeProofOfDeath = (event) => {
        setProofOfDeath(event.target.files[0]);
        setProofOfDeathName(event.target.files[0].name);
        props.handleChangeProofOfDeath(event.target.files[0])
    };

    const handleClickMaritalState = () => {
        maritalStateRef.current.click();
    };

    const changeProofOfMaritalState = (event) => {
        setProofOfMaritalState(event.target.files[0]);
        setProofOfMaritalStateName(event.target.files[0].name)
        props.handleChangeProofOfMaritalState(event.target.files[0])
    };

    const changeAddress = (event) => {
        setAddress(event.target.value)
        props.callbackAddress(event.target.value)
    }

    const changeRG = (event) => {
        setRG(event.target.value)
        props.callbackRg(event.target.value)
    }

    const changeCPF = (event) => {
        var value = event.target.value
        value = value.replace(/\D/g, '');

        if (value.length > 11) {
          value = value.substring(0, 11);
        }
        const formattedValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        // console.log('formattedValue: ', formattedValue)
        setCPF(formattedValue)
        props.callbackCpf(formattedValue)
    }

    const changeCNPJ = (event) => {
        var value = event.target.value;
        const valid = validateCNPJ(cpf)
        // console.log('valid: ', valid)
        value = value.replace(/\D/g, '')

        if (value.length > 14) {
          value = value.substring(0, 14);
        }

        var formattedValue = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        // console.log('formattedValue: ', formattedValue)
        setCPF(formattedValue)
        props.callbackCpf(formattedValue)
    }

    const changeRazaoSocial = (event) => {
        setFirstname(event.target.value)
        props.callbackFirstname(event.target.value)
    }

    const phoneFormat = (event) => {
        // console.log(event.target.value)
        var r = event.target.value.replace(/\D/g,"");
        // console.log('r: ', r)
        r = r.replace(/^0/,"");
        if (r.length > 10) {
            // 11+ digits. Format as 5+4.
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/,"(0$1) $2$3");
        }
        else if (r.length > 5) {
            // 6..10 digits. Format as 4+4
            r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/,"(0$1) $2$3");
        }
        else if (r.length > 2) {
            // 3..5 digits. Add (0..)
            r = r.replace(/^(\d\d)(\d{0,5})/,"(0$1) $2");
        }
        else {
            // 0..2 digits. Just add (0
            r = r.replace(/^(\d*)/, "(0$1");
        }
        setPhone(r)
        props.callbackPhone(event.target.value)
    }

    const emailFormat = (event) => {
        setEmail(event.target.value)
        props.callbackEmail(event.target.value)
    }

    const changeFirstName = (event) => {
        // console.log('chanage first name')
        setFirstname(event.target.value)
        props.callbackFirstname(event.target.value)
    }

    const changeLastName = (event) => {
        setLastname(event.target.value)
        props.callbackLastname(event.target.value)
    }

    const changeBirthdate = (event) => {
        var date = event.target.value
        setBirthdate(date)
        props.callbackBirthdate(date)
    }

  return (
    <div class="row">   
        <div style={{
            'float': "left",
            "width": "100%",
            'marginBottom': '1%'
        }}>
          <FormLabel style={{color: "#000000", marginBottom:"4%"}}>
              <strong>
                  Esse propritário é uma pessoa física ou jurídica?
              </strong>
          </FormLabel>
          <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={person || ''}
          >
              <div class={"row"}>
                  <FormControlLabel onChange={changePerson} value={person} style={{fontSize: "2em"}}
                  value="fisica" control={<Radio style={{color: "#D607BA"}} />} 
                  label="Física" />
                  <FormControlLabel onChange={changePerson} value={person} style={{fontSize: "2em"}}
                  value="juridica" control={<Radio style={{color: "#D607BA"}} />}
                  label="Jurídica" />
              </div>
        </RadioGroup>
    </div>
    <div class={isMobile ? "column":"column"} style={{"width": "100%"}}>
        {person == 'fisica' ?
            <div>   
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"30%"}`,
                    'marginRight':'5%'
                }}>
                    <TextField
                        id="standard"
                        helperText="Qual o seu primeiro nome?"
                        style={{marginBottom: '5%'}}
                        type="text"
                        label="Nome"
                        fullWidth
                        value={firstname || ''}
                        onChange={changeFirstName}
                        variant="outlined"
                    />
                </div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"30%"}`,
                    'marginRight':'5%'
                }}>
                    <TextField
                        id="standard"
                        helperText="Qual o seu sobrenome?"
                        style={{marginBottom: '5%', marginRight: '5%'}}
                        type="text"
                        label="Sobrenome"
                        fullWidth
                        value={lastname || ''}
                        onChange={changeLastName}
                        variant="outlined"
                    />
                </div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"30%"}`
                }}>
                    <TextField
                        id="standard"
                        helperText="Qual sua data de nascimento?"
                        style={{marginBottom: '5%'}}
                        type="text"
                        label="Data de nascimento"
                        fullWidth
                        value={birthdate || ''}
                        onChange={changeBirthdate}
                        InputProps={{
                            inputComponent: InputMask,
                            inputProps: {
                            mask: '99/99/9999',
                            guide: false
                            }
                        }}
                        placeholder="dd/mm/aaaa"
                        variant="outlined"
                    />
                </div>
            </div>
            :
            <div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"45%"}`,
                    'marginRight':'5%',
                    'marginBottom':'1%'
                }}>
                    <TextField
                        id="standard"
                        helperText="Qual a razão social da empresa?"
                        type="text"
                        label="Razão social"
                        fullWidth
                        value={firstname || ''}
                        onChange={changeRazaoSocial}
                        variant="outlined"
                    />
                </div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"45%"}`,
                    'marginRight':'5%',
                    'marginBottom':'1%'
                }}>
                    <TextField
                        error={!validateCNPJ(cpf)}
                        id="standard"
                        helperText="CNPJ"
                        type="text"
                        label="CNPJ"
                        fullWidth
                        value={cpf || ''}
                        onChange={changeCNPJ}
                        variant="outlined"
                    />
                </div>
            </div>
        }
        <div style={{
          'float': `${isMobile ? "":"left"}`,
          'width': `${isMobile ? "100%": (person == 'fisica' ? "30%": "45%")}`,
          'marginRight':'5%'
        }}>
            <TextField
                error={!emailIsValid(email)}
                id="standard"
                helperText="Qual o seu E-mail?"
                style={{marginBottom: '5%'}}
                type="text"
                label="E-mail"
                fullWidth
                value={email || ''}
                onChange={emailFormat}
                variant="outlined"
            />
        </div>
        <div style={{
          'float': `${isMobile ? "":"left"}`,
          'width': `${isMobile ? "100%":(person == 'fisica' ? "30%": "45%")}`,
          'marginRight':'5%'
        }}>
            <TextField
                id="standard"
                helperText="Qual o seu número de celular?"
                style={{marginBottom: '5%'}}
                type="text"
                label="Celular"
                fullWidth
                value={phone|| ''}
                onChange={phoneFormat}
                variant="outlined"
            />
        </div>
        {person == "fisica" ?
            <div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"30%"}`
                }}>
                    <TextField
                        id="standard"
                        helperText="Qual o seu Endereço?"
                        style={{marginBottom: '5%'}}
                        type="text"
                        label="Endereço"
                        fullWidth
                        value={address || ''}
                        onChange={changeAddress}
                        variant="outlined"
                    />
                </div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"30%"}`,
                    'marginRight':'5%'
                }}>
                    <TextField
                        error={!validateCPF(cpf)}
                        id="standard"
                        helperText="Qual seu CPF?"
                        style={{marginBottom: '5%'}}
                        type="text"
                        label="CPF"
                        fullWidth
                        value={cpf || ''}
                        onChange={changeCPF}
                        variant="outlined"
                    />
                </div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"30%"}`,
                    'marginRight':'5%'
                }}>
                    <TextField
                        id="standard"
                        helperText="Qual seu RG?"
                        type="text"
                        style={{marginBottom: '5%'}}
                        label="RG"
                        fullWidth
                        value={rg|| ''}
                        onChange={changeRG}
                        variant="outlined"
                    />
                </div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"30%"}`,
                    'marginBottom': '15px'
                }}>
                    <Mandatory/>
                    <Button
                        onClick={handleClickResidence}
                        fullWidth
                        variant="contained"
                        style={{
                            fontSize: '100%',
                            backgroundColor: "#D607BA"
                        }}
                    >
                        <strong>INSERIR COMPROVANTE DE RESIDÊNCIA</strong>
                    </Button>
                    <input
                        type="file"
                        ref={residenceRef}
                        onChange={changeProofOfResidence}
                        style={{display: 'none'}}
                    />
                    {proofOfResidenceName}
                </div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"30%"}`,
                    'marginRight': '5%',
                }}>
                    <Mandatory/>
                    <Button
                        onClick={handleClickIdentity}
                        fullWidth
                        variant="contained"
                        style={{
                            fontSize: '100%',
                            backgroundColor: "#D607BA"
                        }}
                    >
                        <strong>INSERIR RG OU CNH</strong>
                    </Button>
                    <input
                        type="file"
                        ref={identityRef}
                        onChange={changeProofOfIdentity}
                        style={{display: 'none'}}
                    />
                    {identityName}
                </div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"30%"}`,
                    'marginRight': '5%',
                    'marginBottom': '15px'
                }}>
                    <TextField
                        id="outlined-select-table"
                        select
                        style={{marginTop: '5%'}}
                        fullWidth
                        label="Estado Civil"
                        value={maritalState || ''}
                        onChange={changeMaritalState}
                    >
                        <MenuItem key="married" value="Casado(a)">
                            Casado(a)
                        </MenuItem>
                        <MenuItem key="single" value="Solteiro(a)">
                            Solteiro(a)
                        </MenuItem>
                        <MenuItem key="divorced" value="Divorciado(a)">
                            Divorciado(a)
                        </MenuItem>
                        <MenuItem key="widowed" value="Viúvo(a)">
                            Viúvo(a)
                        </MenuItem>
                    </TextField>
                </div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"20%"}`,
                    'marginRight': '5%',
                }}>
                    <FormLabel style={{color: "#000000", marginBottom:"4%"}}>
                        <strong>
                            Esse propritário é falecido?
                        </strong>
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={dead || ''}
                    >
                        <div class={"row"}>
                            <FormControlLabel onChange={changeDead} value={dead} style={{fontSize: "2em"}}
                            value="Sim" control={<Radio style={{color: "#D607BA"}} />} 
                            label="Sim" />
                            <FormControlLabel onChange={changeDead} value={dead} style={{fontSize: "2em"}}
                            value="Não" control={<Radio style={{color: "#D607BA"}} />}
                            label="Não" />
                        </div>
                    </RadioGroup>
                </div>
                {(maritalState == 'Casado(a)' | maritalState == 'Divorciado(a)') ?
                    <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"30%"}`,
                    'marginRight': '5%',
                    'marginBottom': '15px'
                    }}>
                        <Mandatory/>
                        <Button
                            onClick={handleClickMaritalState}
                            fullWidth
                            variant="contained"
                            style={{
                                fontSize: '100%',
                                backgroundColor: "#D607BA"
                            }}
                        >
                            <strong>INSERIR CERTIDÃO DE CASAMENTO</strong>
                        </Button>
                        <input
                            type="file"
                            ref={maritalStateRef}
                            onChange={changeProofOfMaritalState}
                            style={{display: 'none'}}
                        />
                        {proofOfMaritalStateName}
                    </div>:null
                }
                {dead == 'Sim' ?
                    <div style={{
                        'float': `${isMobile ? "":"right"}`,
                        'width': `${isMobile ? "100%":"30%"}`,
                        'marginBottom': '15px'
                    }}>
                        <Mandatory/>
                        <Button
                            onClick={handleClickDeath}
                            fullWidth
                            variant="contained"
                            style={{
                                fontSize: '100%',
                                backgroundColor: "#D607BA"
                            }}
                        >
                            <strong>INSERIR CERTIDÃO DE ÓBITO</strong>
                        </Button>
                        <input
                            type="file"
                            ref={deathRef}
                            onChange={changeProofOfDeath}
                            style={{display: 'none'}}
                        />
                        {proofOfDeathName}
                    </div>:null 
                }
            </div>:null
        }
        <Dialog open={openMaritalAlert} onClose={handleCloseMaritalAlert}>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Então, se o cônjuge ou ex-cônjuge tiver algum direito sobre o imóvel, clique em "Adicionar proprietário" e insira as informações dele. 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseMaritalAlert} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openDead} onClose={handleCloseDead}>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Então, não esqueçade clicar em "Adicionar proprietário" e insira as informações dos herdeiros, se eles existerem. No E-mail e telefone, colocar os contatos do inventariante. 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDead} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </div>
  </div>
  )
}

export default Owner;