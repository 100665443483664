import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const interest = 1

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#000000' : '#fff',
  ...theme.typography.body6,
  padding: theme.spacing(1),
  color: "#11212D",
  boxShadow: "2px 2px 3px 2px #D607BA"
}));

const LoanData = (props) => {

  const debtValue = props.debtValue
  const loanTime = props.time
  const assetValue = props.assetValue
  const table = props.table
  // const sellOrPay = props.sellOrPay
  const sellOrPay = "pay"

  const lastPayment = (table, debtValue, loanTime) => {
    var integer = (debtValue.split(',')[0].replace(/\D/g, ''))
    var joined = integer + '.'+debtValue.split(',')[1]
    if(table == "Price")
    {
      var total_commition = joined*1.2
      var payment = (total_commition*interest/100)/(1-1/(Math.pow((1+interest/100), loanTime)))
    }
    else {
      var saldo = joined*1.2
      var amortizado = saldo/loanTime
      for(let paymentNumber = 1; paymentNumber <= loanTime; paymentNumber+=1)
      {
        var inter = saldo*interest/100
        var payment =  ((joined*1.2)/loanTime)+inter
        saldo -= amortizado
      }
      console.log("last payment SAC: ", payment)
    }
    

    payment = payment.toFixed(2).replace('.', '')
    const options = { minimumFractionDigits: 2 }
    const result = 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(payment) / 100
    )
    return result
  }

  const payment = (table, debtValue, loanTime) => {
    var integer = (debtValue.split(',')[0].replace(/\D/g, ''))
    var joined = integer + '.'+debtValue.split(',')[1]
    if(table == "Price")
    {
      var total_commition = joined*1.2
      var payment = ((total_commition*interest/100)/(1-1/(Math.pow((1+interest/100), loanTime))))/0.35
    }
    else {
      var payment = (((joined*1.2)/loanTime)+(((joined*1.2))*interest/100))/0.35
    }
    payment = payment.toFixed(2).replace('.', '')
    const options = { minimumFractionDigits: 2 }
    const result = 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(payment) / 100
    )
    return result
  }

  const firstPayment = (table, debtValue, loanTime) => {
    var integer = (debtValue.split(',')[0].replace(/\D/g, ''))
    var joined = integer + '.'+debtValue.split(',')[1]
    if(table == "Price")
    {
      var total_commition = joined*1.2
      var payment = (total_commition*interest/100)/(1-1/(Math.pow((1+interest/100), loanTime)))
    }
    else {
      var payment = ((joined*1.2)/loanTime)+(((joined*1.2))*interest/100)
    }
    payment = payment.toFixed(2).replace('.', '')
    const options = { minimumFractionDigits: 2 }
    const result = 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(payment) / 100
    )
    return result
  }

  const montante = (sellOrPay, table, debtValue, loanTime) => {
    var integer = (debtValue.split(',')[0].replace(/\D/g, ''))
    var joined = integer + '.'+debtValue.split(',')[1]
    console.log("joined--: ", joined)

    if(sellOrPay == "sell")
    {
      var value = (joined*1.2*(1+(interest/100)*loanTime))
    } else {
      if(table == "Price")
      {
        // (B12*B13/100)/(1-1/((1+B13/100)^B5))
        var total_commition = joined*1.2
        var numerator = (total_commition*interest/100)
        var denominator = (1+interest/100)^11
        Math.pow(7, 2);    // 49

        console.log("denominator: ", denominator)

        var payment = (total_commition*interest/100)/(1-1/(Math.pow((1+interest/100), loanTime)))
        console.log("payment: ", payment, numerator)
        var value = loanTime*payment
      }
      else
      {
        var value = 0.00
        var saldo = joined*1.2
        var amortizado = saldo/loanTime
        for(let paymentNumber = 1; paymentNumber <= loanTime; paymentNumber+=1)
        {
          var inter = saldo*interest/100
          var payment =  ((joined*1.2)/loanTime)+inter
          console.log("payment SAC: ", payment)
          saldo -= amortizado
          value += payment
        }
      }
    }

    console.log("value: ", value)
    value = value.toFixed(2).replace('.', '')
    const options = { minimumFractionDigits: 2 }
    const result = 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100
    )
    return result
  }

  const savedValue = (assetValue, debtValue, loanTime) => {
    var asset = Number(assetValue.replace('.', '').replace(',', '.').replace(/\D/g, ''))
    var debt = Number(debtValue.replace('.', '').replace(',', '.').replace(/\D/g, ''))
    let out = asset*0.5-(debt*0.2*(1+(interest/100)*loanTime)).toFixed(2)
    

    console.log('outtt: ', out)
    var value = String(out).replace('.', '').replace(',', '').replace(/\D/g, '')
    console.log(value)
    const options = { minimumFractionDigits: 2 }
    const result = 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100
    )
    console.log('result: ', result)
    return result 
  }

  return (
    <div>
      <Item elevation={3}
          style={{padding: '5%'}}>
          {/* <div align="center">
            <h2><strong>Valor salvo</strong></h2>
            <h3 style={{"color": "#D607BA"}}>
              <strong>
                {savedValue(assetValue, debtValue, loanTime)}
                </strong>
            </h3>
          </div> */}

        <Stack spacing={4}>
          {/* {sellOrPay == "pay" &&  */}
          <Item elevation={3}>
            <div align="center">
              <strong>Parcela inicial / Parcela final</strong><br/>{firstPayment(table, debtValue, loanTime) + " / " + lastPayment(table, debtValue, loanTime)}
            </div>
          </Item>
          {/* } */}
          <Item elevation={3}>
            <div align="center">
              <strong>Montante pago</strong><br/>{montante(sellOrPay, table, debtValue, loanTime)}
            </div>
          </Item>
          <Item elevation={3}>
            <div align="center">
              <strong>Taxa de juros</strong><br/>1% a.m. + IPCA
            </div>
          </Item>
          <Item elevation={3}>
            <div align="center">
              <strong>Renda mensal mínima</strong><br/>{payment(table, debtValue, loanTime)}
            </div>
          </Item>
          <div align="center">
            <b2 style={{"color": "#D607BA"}}><strong>*A taxa de juros depende da análise de crédito, iniciando em 1% ao mês. Os custos com avaliação do imóvel não estão incluídos nas parcelas.</strong></b2>
          </div>
        </Stack>
      </Item>
    </div>
  )
}

export default LoanData;