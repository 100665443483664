import api from '../services/api.js'
import { userInfoNotValid } from '../utils/masks.js'

function changeDuplicates(list) {
    let set = new Set();
    for (let i = 0; i < list.length; i++) {
      if (set.has(list[i])) {
        var ele = list[i].split('@')
        set.add(ele[0] + i + '@' + ele[1])
      }
      else
      {
        set.add(list[i]);
      }
    }
    return Array.from(set);
}

export async function send(ownersHeirs, debt, condDebt, sell, isPaid, proofOfDebt, proofOfCondDebt, dealId, dealname, dealLink, proofOfDebtName, proofOfCondDebtName)
{
    // console.log('proofOfDebt: ', proofOfDebt, proofOfDebtName)
    // console.log('ownersHeirs send: ', ownersHeirs)
    var formData = null
    var listOfPersons = []
    var listOfFirstnames = []
    var listOfLastnames = []
    var listOfFirstnames = []
    var listOfPhones = []
    var listOfEmails = []
    var listOfAddresses = []
    var listOfMaritalStates = []
    var listOfDeaths = []
    var listOfCPFs = []
    var listOfRGs = []
    var listOfBirthdates = []
    var listOfContactIds = []
    var listIdentity = []
    var listProofOfResidence = []
    var listProofOfDeath = []
    var listProofOfMaritalState = []
    var listLinkDrive = []
    var formData = new FormData()
    ownersHeirs.forEach((owner, i) => {

        listOfPersons.push(owner.person + '|');
        listOfFirstnames.push(owner.firstname + '|');
        listOfLastnames.push(owner.lastname + '|');
        listOfPhones.push(owner.phone + '|');
        listOfEmails.push(owner.email + '|');
        listOfAddresses.push(owner.address + '|');
        listOfMaritalStates.push(owner.maritalState + '|');
        listOfDeaths.push(owner.dead + '|');
        listOfCPFs.push(owner.cpf + '|');
        listOfRGs.push(owner.rg + '|');
        listOfBirthdates.push(owner.birthdate+ '|');
        listOfContactIds.push(owner.id + '|');
        listIdentity.push(owner.identityName + '|')
        listProofOfResidence.push(owner.proofOfResidenceName + '|')
        listProofOfDeath.push(owner.proofOfDeathName + '|')
        listProofOfMaritalState.push(owner.proofOfMaritalStateName + '|')
        listLinkDrive.push(owner.link_do_drive + '|')
        formData.append('identity-'+i, owner.identity)
        formData.append('proofOfResidence-'+i, owner.proofOfResidence)
        formData.append('proofOfDeath-'+i, owner.proofOfDeath)
        formData.append('proofOfMaritalState-'+i, owner.proofOfMaritalState)
    })

    listOfEmails = changeDuplicates(listOfEmails)

    debt = debt.replace('.', '').replace(',', '').replace(/\D/g, '')
    debt = parseFloat(debt)/100

    condDebt = condDebt.replace('.', '').replace(',', '').replace(/\D/g, '')
    condDebt = parseFloat(condDebt)/100
    
    formData.append('debt', debt);
    formData.append('condDebt', condDebt);
    formData.append('isPaid', isPaid);
    formData.append('sell', sell);
    formData.append('proofOfDebt', proofOfDebt);
    formData.append('proofOfDebtName', proofOfDebtName);
    formData.append('proofOfCondDebt', proofOfCondDebt);
    formData.append('proofOfCondDebtName', proofOfCondDebtName);
    
    formData.append('firstname', listOfFirstnames);
    formData.append('person', listOfPersons);
    formData.append('linkDrive', listLinkDrive);
    formData.append('lastname', listOfLastnames);
    formData.append('phone', listOfPhones);
    formData.append('email', listOfEmails);
    formData.append('address', listOfAddresses);
    formData.append('maritalState', listOfMaritalStates);
    formData.append('birthdate', listOfBirthdates);
    formData.append('CPF', listOfCPFs);
    formData.append('RG', listOfRGs);
    formData.append('contactId', listOfContactIds);
    formData.append('dead', listOfDeaths);
    formData.append('dealId', dealId)
    formData.append('dealname', dealname)
    formData.append('identityName', listIdentity);
    formData.append('proofOfResidenceName', listProofOfResidence);
    formData.append('proofOfDeathName', listProofOfDeath);
    formData.append('proofOfMaritalStateName', listProofOfMaritalState);
    formData.append('dealLink', dealLink)
    // console.log('lists: ', listOfFirstnames)
    // console.log('formData: ', formData)
    const res = await api.post('due-diligence', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
    return res
}
