import { TextField, RadioGroup, Radio, DialogActions, FormLabel, FormControlLabel, Button, MenuItem, Dialog, DialogContent, DialogContentText } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Mandatory from '../messages/Mandatory'
import { InputMask } from 'react-text-mask';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    input: {
      display: 'none',
    },
}));

const Asset = (props) => {

    console.log('props: ', props)

    const [valor, setValor] = React.useState(props.assetValue)   

    const isMobile = props.isMobile
    const [isPaid, setIsPaid] = React.useState(props.isPaid);
    const [sell, setSell] = React.useState(props.sell);
    const [debt, setDebt] = React.useState(props.debt);
    const [condDebt, setCondDebt] = React.useState(props.condDebt);
    const [proofOfDebt, setProofOfDebt] = React.useState(props.proofOfDebt);
    const [proofOfDebtName, setProofOfDebtName] = React.useState(props.proofOfDebtName);
    const [proofOfCondDebt, setProofOfCondDebt] = React.useState(props.proofOfCondDebt);
    const [proofOfCondDebtName, setProofOfCondDebtName] = React.useState(props.proofOfCondDebtName);
    const isPaidRef = useRef(null);
    const condDebtRef = useRef(null);

    const [error, setError] = React.useState(null);


    function condDebtFormat(event){
        var value = event.target.value.replace('.', '').replace(',', '').replace(/\D/g, '')
        console.log('value: ' + value)
        const options = { minimumFractionDigits: 2 }
        const result = 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(
          parseFloat(value)/100
        )
        console.log('result: ' + result)
        setCondDebt(result)
        props.callbackCondDebt(result)
    }

    function bankDebtFormat(event){
        var value = event.target.value.replace('.', '').replace(',', '').replace(/\D/g, '')
        console.log('value: ' + value)
        const options = { minimumFractionDigits: 2 }
        const result = 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(
          parseFloat(value)/100
        )
        console.log('result: ' + result)
        setDebt(result)
        props.callbackDebt(result)
    }

    const changeIsPaid = (event) => {
        setIsPaid(event.target.value)
        props.callbackIsPaid(event.target.value)
        if(event.target.value){
            setDebt("R$ 0,00")
        }
    }

    const changeDebt = (event) => {
        setDebt(event.target.value)
        props.callbackDebt(event.target.value)
    }

    const changeSell = (event) => {
        setSell(event.target.value)
        props.callbackSell(event.target.value)
    }

    const handleClickIsPaid = () => {
        isPaidRef.current.click();
    };

    const handleClickDebtCond = () => {
        condDebtRef.current.click();
    };

    const handleChangeProofOfDebt  = (event) => {
        setProofOfDebt(event.target.files[0]);
        setProofOfDebtName(event.target.files[0].name);
        props.callbackChangeProofOfDebt(event.target.files[0])
    };

    const handleChangeUpdatedDebtCond  = (event) => {
        setProofOfCondDebt(event.target.files[0]);
        setProofOfCondDebtName(event.target.files[0].name);
        props.callbackChangeProofOfCondDebt(event.target.files[0])
    };

    useEffect(() => {
        var value = props.debt.replace('.', '').replace(',', '').replace(/\D/g, '')
        // console.log('value: ' + value)
        const options = { minimumFractionDigits: 2 }
        const result = 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(
          parseFloat(value)/100
          )
        setDebt(result)
    }, [])

    return (
        <div class={isMobile ? "column":"column"}>
            <div style={{
              'float': `${isMobile ? "":"left"}`,
              'width': `${isMobile ? "100%":"45%"}`,
              'marginRight': '5%',
              'marginBottom': '1%',
            }}>
                <TextField
                    id="outlined-select-table"
                    select
                    fullWidth
                    label="Você quer vender seu imóvel?"
                    value={sell}
                    onChange={changeSell}
                >
                    <MenuItem key="yes" value={true}>
                        SIM
                    </MenuItem>
                    <MenuItem key="no" value={false}>
                        NÃO
                    </MenuItem>
                </TextField>
            </div>
            <div style={{
              'float': `${isMobile ? "":"left"}`,
              'width': `${isMobile ? "100%":"45%"}`,
              'marginRight': '5%',
              'marginBottom': '1%'
            }}>
                <TextField
                    id="outlined-select-table"
                    select
                    fullWidth
                    label="Seu imóvel já está quitado?"
                    value={isPaid}
                    onChange={changeIsPaid}
                >
                    <MenuItem key="yes" value={true}>
                        SIM
                    </MenuItem>
                    <MenuItem key="no" value={false}>
                        NÃO
                    </MenuItem>
                </TextField>
            </div>
            <div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"45%"}`,
                    'marginRight':'5%'
                }}>
                        <TextField
                            id="standard"
                            helperText="Qual o valor atualizado da sua dívida com o CONDOMÌNIO?"
                            label="Valor da dívida CONDOMINIAL"
                            fullWidth
                            value={condDebt}
                            onChange={condDebtFormat}
                            variant="outlined"
                        />
                </div>
                <div style={{
                    'float': `${isMobile ? "":"left"}`,
                    'width': `${isMobile ? "100%":"45%"}`,
                    'marginRight': '5%',
                    'marginBottom': '15px'
                }}>
                    <Mandatory/>
                    <Button
                        onClick={handleClickDebtCond}
                        fullWidth
                        variant="contained"
                        style={{
                            fontSize: '100%',
                            backgroundColor: "#D607BA"
                        }}
                    >
                        <strong>COMPROVANTE DO VALOR ATUALIZADO DA DÍVIDA CONDOMINIAL</strong>
                    </Button>
                    <input
                        type="file"
                        ref={condDebtRef}
                        onChange={handleChangeUpdatedDebtCond}
                        style={{display: 'none'}}
                    />
                    {proofOfCondDebtName}
                </div>
                </div>
            {isPaid == false ?
                <div>
                    <div style={{
                        'float': `${isMobile ? "":"left"}`,
                        'width': `${isMobile ? "100%":"45%"}`,
                        'marginRight':'5%'
                    }}>
                            <TextField
                                id="standard"
                                helperText="Qual o valor da sua dívida com o BANCO?"
                                label="Valor da dívida"
                                fullWidth
                                value={debt}
                                onChange={bankDebtFormat}
                                variant="outlined"
                            />
                    </div>
                    <div style={{
                        'float': `${isMobile ? "":"left"}`,
                        'width': `${isMobile ? "100%":"45%"}`,
                        'marginRight': '5%',
                        'marginBottom': '15px'
                    }}>
                        <Mandatory/>
                        <Button
                            onClick={handleClickIsPaid}
                            fullWidth
                            variant="contained"
                            style={{
                                fontSize: '100%',
                                backgroundColor: "#D607BA"
                            }}
                        >
                            <strong>EXTRATO DO SALDO DEVEDOR DO FINANCIAMENTO</strong>
                        </Button>
                        <input
                            type="file"
                            ref={isPaidRef}
                            onChange={handleChangeProofOfDebt}
                            style={{display: 'none'}}
                        />
                        {proofOfDebtName}
                    </div>
                </div>:null 
            }
        </div>
    )
}

export default Asset;